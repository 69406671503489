// color
$COLOR: (
  pale-blue: #80B9DA,
  pale-gray: #F9F9F9,
  middle-gray: #F0F0F0,
	gray: #E5E5E5,
  pale-orange: #FFCC80,
	orange: #FFA200
) !default;

$COLOR_TEXT: #222;
$COLOR_TEXT_GRAY: #9c9c9c;
$COLOR_MAIN: #1847c7;
$COLOR_MAIN_ORANGE: #ff7f00;

// base
$BASE: (
	font-size: 16px,
	line-height: 1.8,
) !default;

// brake-point
$BRAKE_POINT: (
  sp: 600px,
  tbP: 768px,
  tbL: 992px,
  pc: 1200px,
);

// container-padding
$PADDING: (
  container: 15,
) !default;

// container-size
$CONTAINER: (
  sm: #{map_get($PADDING, container) * 2 + 750px},
  md: #{map_get($PADDING, container) * 2 + 970px},
  lg: #{map_get($PADDING, container) * 2 + 1200px},
) !default;

// font-family
$FONT_BASE: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'メイリオ', Meiryo, 'Hiragino Kaku Gothic ProN', sans-serif;
$FONT_ALPHA: 'Lato', Arial, Helvetica, sans-serif;
$FONT_ALPHA_SPARTAN: 'Lato', Arial, Helvetica, sans-serif;
$FONT_MINCHO: "Ryumin Medium KL", '游明朝体', 'Yu Mincho', 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', serif;
$FONT_ICON: 'Material Icons';
$FONT_NUMBER: 'Barlow', sans-serif;
