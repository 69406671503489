@charset "UTF-8";
/* 余白 8の倍数 */
/* clampを使用し、指定したプロパティをレスポンシブに可変 */
/*
top
*/
/* margin / 余白 */
.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb150 {
  margin-bottom: 150px;
}

/* ==[ .p-top-hero ]==================================== */
.p-top-hero .p-top-mv__inner {
  margin-left: auto;
  padding-bottom: calc( 8px * 3 );
  width: 95.83%;
  position: relative;
}
.p-top-hero .p-top-mv .mv-ttl {
  position: absolute;
  left: 52px;
  /* top: 50%; */
  position: absolute;
  bottom: 4vw;
  transform: translateY(-50%);
  z-index: 3;
  width: 43%;
  min-width: 420px;
}
@media not all and (min-width: 992px) {
  .p-top-hero .p-top-mv .mv-ttl {
    bottom: 0;
    max-width: 100%;
    transform: translateY(-50%);
  }
}
@media not all and (min-width: 600px) {
  .p-top-hero .p-top-mv .mv-ttl {
    transform: translate(-50%, -10%);
    left: 50%;
    padding: 0 16px;
    bottom: 20%;
    width: 100%;
  }
}
.p-top-hero .p-top-mv .animat-box {
  width: 450px;
  position: absolute;
  /* top: 100px; */
  bottom: 5vw;
  width: 35%;
  max-width: 590px;
  left: -110px;
  z-index: 2;
}
@media not all and (min-width: 768px) {
  .p-top-hero .p-top-mv .animat-box {
    width: 400px;
  }
}
@media not all and (min-width: 600px) {
  .p-top-hero .p-top-mv .animat-box {
    width: 400px;
  }
}
@media screen and (max-width: 425px) {
  .p-top-hero .p-top-mv .animat-box {
    bottom: 30px;
    top: auto;
    width: 225px;
    left: -52px;
    bottom: 15%;
  }
}
.p-top-hero .p-top-mv .p-search-area {
  background-color: white;
  padding: 40px 24px;
  /* width: 624px; */
  width: 52vw;
  max-width: 624px;
  min-width: 400px;
  position: absolute;
  right: 0;
  z-index: 3;
}
.p-top-hero .p-top-mv .p-search-area.pc-search {
  bottom: 0;
}
@media not all and (min-width: 992px) {
  .p-top-hero .p-top-mv .p-search-area.pc-search {
    display: none;
  }
}
.p-top-hero .p-top-mv .p-search-area.sp-search {
  display: none;
}
@media not all and (min-width: 992px) {
  .p-top-hero .p-top-mv .p-search-area.sp-search {
    display: block;
  }
}
@media not all and (min-width: 992px) {
  .p-top-hero .p-top-mv .p-search-area {
    box-shadow: 0px 7px 15px 1px rgba(0, 0, 0, 0.1);
    padding: calc( 8px * 4 );
    position: static;
    right: auto;
    bottom: auto;
    width: 100%;
    max-width: 100%;
    min-width: auto;
  }
}
@media not all and (min-width: 600px) {
  .p-top-hero .p-top-mv .p-search-area {
    padding: 40px 24px;
  }
}
.p-top-hero .p-top-mv .p-search-area .p-search-unit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 18px;
}
@media not all and (min-width: 768px) {
  .p-top-hero .p-top-mv .p-search-area .p-search-unit {
    flex-direction: column;
    gap: 16px 0;
  }
}
@media not all and (min-width: 768px) {
  .p-top-hero .p-top-mv .p-search-area .p-search-unit li {
    text-align: center;
    width: 100%;
  }
}
.p-top-hero .p-top-mv .p-search-area .custom-select {
  height: 42px;
  width: 326px;
  max-width: 100%;
  transition: 0.3s;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  /* &:focus{
    background-color: #f2f2f2;
    box-shadow: 0 0 10px 0 rgba(45, 45, 45, 0.15);
  }
  &:focus-visible {
    outline: none !important;
  } */
}
.p-top-hero .p-top-mv .p-search-area .custom-select:hover {
  background-color: #e3e7eb;
}
.p-top-hero .p-top-mv .p-search-area .custom-select:focus:not(:focus-visible) {
  outline: none;
}
.p-top-hero .p-top-mv .p-search-area .custom-select:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}
@media not all and (min-width: 768px) {
  .p-top-hero .p-top-mv .p-search-area .custom-select {
    height: 42px;
    background: #fff url(../img/common/icon_select_btn.svg) 100%/42px 42px no-repeat;
    background-color: #f0f0f0;
    font-size: 14px;
  }
}
@media not all and (min-width: 768px) {
  .p-top-hero .p-top-mv .p-search-area .custom-select {
    width: 100%;
  }
}
.p-top-hero .p-top-mv .p-search-area .c-btn {
  line-height: 42px;
  padding: 0 8px;
  width: 167px;
  max-width: 100%;
}
.p-top-hero .p-top-mv .p-search-area .c-btn span {
  font-size: 14px;
}
@media not all and (min-width: 768px) {
  .p-top-hero .p-top-mv .p-search-area .c-btn {
    line-height: 54px;
    width: 326px;
    font-size: 16px;
  }
}
@media not all and (min-width: 600px) {
  .p-top-hero .p-top-mv .p-search-area .c-btn {
    width: 100%;
  }
}
@media not all and (min-width: 768px) {
  .p-top-hero .p-top-mv .slider-mv .swiper-slide__inner {
    /* height: 460px; */
  }
  .p-top-hero .p-top-mv .slider-mv .swiper-slide__inner img {
    width: 100%;
    height: 100%;
  }
}
.p-top-hero .p-top-mv .slider-mv .swiper-slide__inner:hover {
  opacity: 1;
}
.p-top-hero .p-top-mv .slider-mv .swiper-pagination-mv {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 8px 0;
  width: auto;
  position: absolute;
  bottom: auto;
  left: auto;
  top: 50%;
  right: 32px;
  transform: translateY(-50%);
  z-index: 2;
}
.p-top-hero .p-top-mv .slider-mv .swiper-pagination-mv .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  margin-right: 4px;
  width: 8px;
  height: 8px;
  opacity: 1;
}
.p-top-hero .p-top-mv .slider-mv .swiper-pagination-mv .swiper-pagination-bullet-active {
  background-color: transparent;
  background-image: url(../img/common/pagination_bullet_active.svg);
  background-size: 100% auto;
  border-radius: 0;
  margin: 0;
  width: 17px;
  height: 17px;
  opacity: 1;
}
.p-top-hero .p-top-news {
  background-color: #1847c7;
  padding-top: 245px;
  margin-top: -245px;
  /* 20241114加筆 */
}
.p-top-hero .p-top-news__inner {
  margin-left: auto;
  padding-right: calc( 8px * 5 );
  padding-bottom: calc( 8px * 4 );
  width: 95.83%;
  position: relative;
  overflow: hidden;
  z-index: 5;
}
@media not all and (min-width: 992px) {
  .p-top-hero .p-top-news__inner {
    padding-right: calc( 8px * 2 );
    padding-bottom: calc( 8px * 4 );
  }
}
.p-top-hero .p-top-news .slider-news {
  position: relative;
}
.p-top-hero .p-top-news .slider-news .swiper-slide {
  background-color: #1847c7;
}
.p-top-hero .p-top-news .slider-news .swiper-pagination-news {
  width: auto;
  /* position: absolute;
  bottom: auto;
  top: -44px;
  left: 140px; */
}
.p-top-hero .p-top-news .slider-news .swiper-pagination-news .swiper-pagination-bullet {
  background-color: white;
  border-radius: 30px;
  height: 4px;
  width: 49px;
}
@media not all and (min-width: 768px) {
  .p-top-hero .p-top-news .slider-news .swiper-pagination-news .swiper-pagination-bullet {
    height: 3px;
    width: 42px;
  }
}
.p-top-hero .p-top-news .myBlockNews__title {
  background-color: #F0F0F0;
  color: #1847c7;
  display: inline-block;
  font-size: calc(14px + 2 * (100vw - 375px) / 1545);
  font-weight: 700;
  padding: 8px 12px;
  margin-bottom: 16px;
  text-align: center;
  width: 104px;
  line-height: 1;
  white-space: nowrap;
}
@media not all and (min-width: 768px) {
  .p-top-hero .p-top-news .myBlockNews__title {
    padding: 6px;
    width: 100px;
  }
}
.p-top-hero .p-top-news .myBlockNews__list {
  display: flex;
  gap: 5px 0;
  flex-direction: column;
}
.p-top-hero .p-top-news .myBlockNews__list time,
.p-top-hero .p-top-news .myBlockNews__list span {
  font-size: 16px;
}
@media not all and (min-width: 768px) {
  .p-top-hero .p-top-news .myBlockNews__list time,
.p-top-hero .p-top-news .myBlockNews__list span {
    font-size: 14px;
  }
}
.p-top-hero .p-top-news .myBlockNews__list-item {
  color: white;
  line-height: normal;
  text-decoration: none;
}
.p-top-hero .p-top-news .myBlockNews__list-update {
  display: inline-block;
  width: 160px;
}
@media not all and (min-width: 768px) {
  .p-top-hero .p-top-news .myBlockNews__list-update {
    width: 140px;
  }
}
.p-top-hero .p-top-news .myBlockNews__list-text {
  font-size: clamp(14px, 1.05vw + 5.95px, 16px);
}

/* ==[ .p-top-msg ]==================================== */
.p-top-msg .sec-inner {
  overflow: hidden;
  padding-top: calc( 8px * 13 );
  padding-bottom: calc( 8px * 18 );
}
@media not all and (min-width: 768px) {
  .p-top-msg .sec-inner {
    padding-top: calc( 8px * 8 );
    padding-bottom: calc( 8px * 10 );
  }
}
.p-top-msg .c-container-lg {
  padding: 0 24px;
}
.p-top-msg .c-ttl-unit {
  margin-bottom: calc( 8px * 5 );
}
@media not all and (min-width: 768px) {
  .p-top-msg .c-ttl-unit {
    margin-bottom: calc( 8px * 4 );
  }
}
.p-top-msg .ttl-main {
  color: #231815;
  font-size: calc(36px + 30 * (100vw - 375px) / 1600);
  line-height: 1.579;
  word-break: keep-all;
}
@media (min-width: 1680px) {
  .p-top-msg .ttl-main {
    font-size: 62px;
  }
}
@media screen and (max-width: 374px) {
  .p-top-msg .ttl-main {
    font-size: calc(34px + 4 * (100vw - 375px) / 1545);
  }
}
.p-top-msg .msg-txt {
  font-size: calc(18px + 4 * (100vw - 375px) / 1545);
  font-weight: 700;
  line-height: 1.8;
  line-height: clamp(40px, 4.8vh, 48px);
  word-break: keep-all;
}
@media screen and (max-width: 374px) {
  .p-top-msg .msg-txt {
    font-size: calc(16px + 2 * (100vw - 375px) / 1545);
  }
}
.p-top-msg .animat-msg {
  width: 90%;
  max-width: 1098px;
  bottom: -50px;
  right: -28vw;
}
@media not all and (min-width: 992px) {
  .p-top-msg .animat-msg {
    right: -25vw;
    max-width: 100%;
  }
}
@media not all and (min-width: 768px) {
  .p-top-msg .animat-msg {
    bottom: -10vw;
    right: -30vw;
  }
}

/* ==[ .p-top-philosophy ]==================================== */
.p-top-philosophy {
  background-color: #F0F0F0;
}
.p-top-philosophy .sec-inner {
  padding-top: 0;
  padding-bottom: calc( 8px * 14 );
  position: relative;
}
@media not all and (min-width: 768px) {
  .p-top-philosophy .sec-inner {
    padding-bottom: calc( 8px * 4 );
  }
}
@media not all and (min-width: 600px) {
  .p-top-philosophy .sec-inner {
    padding-bottom: calc( 8px * 7 );
  }
}
.p-top-philosophy .p-heading-unit {
  background-image: url(../img/top/philosophy/philosophy_ttl_bg.svg);
  background-position: left center;
  background-size: contain;
  background-repeat: repeat-x;
  width: 100%;
  height: 150px;
}
.p-top-philosophy .p-heading-unit.heading-type1 {
  top: -84px;
  /* left: 120%; */
}
.p-top-philosophy .p-heading-unit.heading-anime1 {
  left: 0;
}
.p-top-philosophy .p-heading-flex {
  gap: 0 30px;
}
.p-top-philosophy .container-full {
  width: 100%;
  max-width: 100%;
  position: absolute;
  bottom: 12%;
  left: 50%;
  transform: translateX(-50%);
}
@media not all and (min-width: 768px) {
  .p-top-philosophy .container-full {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    margin-top: -80px;
  }
}
.p-top-philosophy .width-md {
  position: relative;
}
@media not all and (min-width: 768px) {
  .p-top-philosophy .width-md {
    margin: 0 auto;
    width: calc(100% - 32px);
  }
}
.p-top-philosophy .p-philosophy-bg {
  height: 400px;
  height: clamp(350px, 15.45vw + 223.27px, 520px);
  position: relative;
}
@media not all and (min-width: 768px) {
  .p-top-philosophy .p-philosophy-bg {
    height: auto;
    max-height: 500px;
  }
}
.p-top-philosophy .p-philosophy-bg::before {
  content: "";
  display: block;
  padding-top: 42.2037%;
}
@media not all and (min-width: 768px) {
  .p-top-philosophy .p-philosophy-bg::before {
    padding-top: 72%;
  }
}
.p-top-philosophy .p-philosophy-bg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ==[ .p-top-company ]==================================== */
.p-top-company .animat-common {
  width: 1200px;
  max-width: 100%;
  top: 50%;
  right: -20vh;
  transform: translateY(-50%);
  z-index: 0;
}
.p-top-company .animat-common.bottom {
  bottom: -50px;
  right: -20vw;
  z-index: 0;
}
@media not all and (min-width: 768px) {
  .p-top-company .animat-common {
    display: none;
  }
}
.p-top-company .container-full {
  padding-top: calc( 8px * 6 );
  padding-bottom: calc( 8px * 4 );
  position: relative;
  z-index: 1;
}
@media not all and (min-width: 768px) {
  .p-top-company .container-full {
    padding-bottom: 250px;
  }
}
@media not all and (min-width: 600px) {
  .p-top-company .container-full {
    padding-bottom: 160px;
    padding-top: 62px;
  }
}
.p-top-company .p-heading-unit.heading, .p-top-company .p-heading-unit.heading-type2 {
  padding-left: 6.5%;
  height: 130px;
  top: 0;
  left: 0;
}
@media not all and (min-width: 768px) {
  .p-top-company .p-heading-unit.heading, .p-top-company .p-heading-unit.heading-type2 {
    padding-left: calc( 8px * 2 );
    top: -5px;
  }
}
.p-top-company .width-sm {
  box-shadow: 0px 7px 15px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.p-top-company .width-lg {
  margin-left: auto;
  position: relative;
  z-index: 0;
}
.p-top-company .col-img {
  height: 400px;
  height: clamp(350px, 15.45vw + 223.27px, 520px);
  padding: 0;
}
@media not all and (min-width: 768px) {
  .p-top-company .col-img {
    height: auto;
  }
}
@media not all and (min-width: 600px) {
  .p-top-company .col-img {
    height: 350px;
  }
}
.p-top-company .col-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-top-company .col-img img.object-fit-img {
  object-position: right;
}
@media not all and (min-width: 768px) {
  .p-top-company .col-img img.object-fit-img {
    object-position: center;
  }
}

/* ==[ .p-top-work ]==================================== */
.p-top-work .animat-common {
  width: 1200px;
  max-width: 100%;
  z-index: 0;
}
.p-top-work .animat-common.bottom {
  bottom: -50px;
  right: -20vw;
  z-index: 0;
}
@media not all and (min-width: 768px) {
  .p-top-work .animat-common {
    display: none;
  }
}
.p-top-work .sec-inner {
  padding: 48px 0 0;
  margin-bottom: calc( 8px * 9 );
  position: relative;
  z-index: 1;
}
@media not all and (min-width: 768px) {
  .p-top-work .sec-inner {
    margin-bottom: 0;
    padding-bottom: 92px;
  }
}
@media not all and (min-width: 600px) {
  .p-top-work .sec-inner {
    padding: 64px 0 0;
  }
}
.p-top-work .c-container-lg.p-absolute {
  position: absolute;
  top: 0;
  z-index: 2;
}
.p-top-work .p-heading-unit {
  max-width: 100%;
}
@media screen and (max-width: 576px) {
  .p-top-work .p-heading-unit.heading-type2 {
    top: -35px;
  }
}
@media screen and (max-width: 576px) {
  .p-top-work .p-heading-unit .p-heading-flex {
    white-space: normal;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 374px) {
  .p-top-work .p-heading-unit .p-heading-flex {
    flex-wrap: nowrap;
  }
}
.p-top-work .p-heading-unit .p-heading-flex li {
  font-size: calc(52px + 86 * (100vw - 320px) / 1600);
}
@media screen and (max-width: 374px) {
  .p-top-work .p-heading-unit .p-heading-flex li {
    font-size: calc(52px + 86 * (100vw - 320px) / 1600);
  }
}
@media screen and (max-width: 576px) {
  .p-top-work .p-heading-unit .p-heading-flex li span {
    padding: 0 12px;
  }
}
.p-top-work .pc-wrap {
  display: block;
}
@media screen and (max-width: 576px) {
  .p-top-work .pc-wrap {
    display: none;
  }
}
.p-top-work .sp-wrap {
  display: none;
}
@media screen and (max-width: 576px) {
  .p-top-work .sp-wrap {
    display: block;
  }
}

/* ==[ .p-top-people ]==================================== */
.p-top-people .sec-inner {
  padding-top: 0;
  padding-bottom: calc( 8px * 7 );
}
@media not all and (min-width: 768px) {
  .p-top-people .sec-inner {
    padding-top: 16px;
    padding-bottom: 92px;
  }
}
@media not all and (min-width: 600px) {
  .p-top-people .sec-inner {
    padding: 32px 0 0;
  }
}
.p-top-people .col-right {
  position: relative;
}
.p-top-people .animation-wrap {
  position: relative;
  overflow: hidden;
  padding: 0;
}
.p-top-people .animation-wrap .loop-anim-unit {
  padding: 0 72px;
  width: 80%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
@media not all and (min-width: 992px) {
  .p-top-people .animation-wrap .loop-anim-unit {
    padding-right: 24px;
    width: calc(100% - 140px);
  }
}
@media not all and (min-width: 768px) {
  .p-top-people .animation-wrap .loop-anim-unit {
    padding: 0 16px;
    padding-left: 40px;
    width: 100%;
  }
}
.p-top-people .animation-wrap .loop-anim-unit .flex-unit {
  display: flex;
  justify-content: flex-end;
  gap: 0 35px;
  padding: 0;
}
@media not all and (min-width: 768px) {
  .p-top-people .animation-wrap .loop-anim-unit .flex-unit {
    gap: 0 18px;
  }
}
.p-top-people .animation-wrap .loop-anim-unit .flex-unit .flex-item {
  display: flex;
  flex-direction: column;
  gap: 30px 0;
  transform: translate3d(0, 0, 0);
  width: 50%;
}
@media not all and (min-width: 768px) {
  .p-top-people .animation-wrap .loop-anim-unit .flex-unit .flex-item {
    gap: 20px 0;
  }
}
.p-top-people .animation-wrap .loop-anim-unit .flex-unit .flex-item.item-up {
  text-align: right;
  animation: loopUp 35s linear infinite;
}
.p-top-people .animation-wrap .loop-anim-unit .flex-unit .flex-item.item-down {
  animation: loopDown 35s linear infinite;
}
.p-top-people .animation-wrap .loop-anim-unit img {
  border-radius: 14px;
  width: auto;
  height: auto;
  min-width: 120px;
}

/* ==[ .p-top-story ]==================================== */
.p-top-story .sec-inner {
  padding-top: calc( 8px * 5 );
  padding-bottom: 0;
}
@media not all and (min-width: 768px) {
  .p-top-story .sec-inner {
    padding-bottom: 92px;
  }
}
@media not all and (min-width: 600px) {
  .p-top-story .sec-inner {
    padding: 64px 0 0;
    margin-top: 32px;
  }
}

/* ==[ .p-top-environment ]==================================== */
.p-top-environment {
  color: white;
}
.p-top-environment .sec-inner {
  padding-top: calc( 8px * 11 );
  padding-bottom: 0;
}
@media not all and (min-width: 600px) {
  .p-top-environment .sec-inner {
    padding: 64px 0 0;
    margin-top: 32px;
  }
}
.p-top-environment .ttl-main {
  color: white;
}
.p-top-environment .heading {
  height: 125px;
  width: 1200px;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.p-top-environment .heading .p-heading-flex {
  justify-content: flex-end;
}
.p-top-environment .width-xl {
  background: url(../img/top/environment/environment_bg.jpg) no-repeat center center;
  background-size: cover;
  padding: 88px 0 72px;
  margin-left: auto;
  position: relative;
}
@media not all and (min-width: 768px) {
  .p-top-environment .width-xl {
    background: url(../img/top/environment/environment_bg_sp.jpg) no-repeat center center;
    background-size: cover;
    padding: 88px 0;
  }
}
@media not all and (min-width: 600px) {
  .p-top-environment .width-xl {
    padding: 72px 0 64px;
  }
}
.p-top-environment .width-xl::after {
  background-color: rgba(23, 70, 199, 0.75);
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.p-top-environment .c-container-xl {
  position: relative;
  z-index: 1;
}
.p-top-environment .c-flex-container {
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
}
@media not all and (min-width: 768px) {
  .p-top-environment .c-flex-container {
    flex-wrap: wrap;
  }
}
.p-top-environment .c-flex-container .c-flex-item {
  width: calc(50% - 40px);
  word-break: keep-all;
}
@media not all and (min-width: 768px) {
  .p-top-environment .c-flex-container .c-flex-item {
    width: 100%;
  }
}
@media not all and (min-width: 768px) {
  .p-top-environment .c-contxt {
    margin-bottom: calc( 8px * 11 );
  }
}
@media not all and (min-width: 600px) {
  .p-top-environment .c-contxt {
    margin-bottom: calc( 8px * 3 );
  }
}

/* ==[ .p-top-recruit ]==================================== */
.p-top-recruit {
  overflow: hidden;
}
.p-top-recruit .animat-common {
  width: 1200px;
  max-width: 100%;
  top: 50%;
  right: -50vh;
  transform: translateY(-50%);
  z-index: 0;
}
@media not all and (min-width: 992px) {
  .p-top-recruit .animat-common {
    width: 858px;
    top: -10vw;
    right: -30vw;
    transform: translateY(0);
  }
}
@media not all and (min-width: 768px) {
  .p-top-recruit .animat-common {
    top: 0;
  }
}
.p-top-recruit .animat-common.bottom {
  display: none;
}
@media not all and (min-width: 992px) {
  .p-top-recruit .animat-common.bottom {
    display: block;
    width: 858px;
    top: auto;
    bottom: -50px;
    right: -30vw;
  }
}
@media not all and (min-width: 768px) {
  .p-top-recruit .animat-common.bottom {
    bottom: 0;
  }
}
.p-top-recruit .sec-inner {
  padding: 56px 0 120px;
  position: relative;
  z-index: 1;
}
@media not all and (min-width: 768px) {
  .p-top-recruit .sec-inner {
    padding: 56px 0 136px;
  }
}
@media not all and (min-width: 600px) {
  .p-top-recruit .sec-inner {
    padding: 56px 0 80px;
  }
}
.p-top-recruit .outer-unit {
  margin: 0 auto;
  padding-top: 120px;
  width: 87.91666%;
  position: relative;
}
@media not all and (min-width: 768px) {
  .p-top-recruit .outer-unit {
    padding-top: 30px;
    width: calc(100% - 32px);
  }
}
.p-top-recruit .heading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
@media not all and (min-width: 768px) {
  .p-top-recruit .heading {
    padding-left: 0;
    padding-right: 16px;
    top: -5px;
    left: auto;
    right: 0;
  }
}
.p-top-recruit .heading .p-heading-flex {
  height: 125px;
  align-items: flex-end;
}
@media not all and (min-width: 768px) {
  .p-top-recruit .heading .p-heading-flex {
    height: auto;
  }
}
.p-top-recruit .width-custom {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 7px 15px 1px rgba(0, 0, 0, 0.13);
  padding: 62px 0;
  width: 100%;
  max-width: 100%;
}
.p-top-recruit .c-ttl-unit {
  text-align: center;
  margin-bottom: 40px;
}
@media not all and (min-width: 600px) {
  .p-top-recruit .c-ttl-unit {
    margin-bottom: 32px;
  }
}
.p-top-recruit .ttl-main,
.p-top-recruit .c-card-title {
  color: #1847c7;
}
.p-top-recruit .c-flex-container {
  padding: 0 16px;
  justify-content: center;
}
@media not all and (min-width: 768px) {
  .p-top-recruit .c-flex-container {
    flex-wrap: wrap;
  }
}
.p-top-recruit .c-flex-container .c-flex-item {
  width: 33.3333333333%;
}
@media not all and (min-width: 768px) {
  .p-top-recruit .c-flex-container .c-flex-item {
    width: 100%;
  }
}
@media not all and (min-width: 768px) {
  .p-top-recruit .c-flex-container .c-flex-item + .c-flex-item {
    margin-top: calc( 8px * 8 );
  }
}
@media not all and (min-width: 600px) {
  .p-top-recruit .c-flex-container .c-flex-item + .c-flex-item {
    margin-top: calc( 8px * 0 );
  }
}
.p-top-recruit .c-flex-container .c-flex-item:hover + .c-flex-item .card-recruit {
  border-left-color: white;
}
.p-top-recruit .card-recruit {
  border-left: 1px #E5E5E5 solid;
  background-color: white;
  color: white;
  padding: 22px 0 34px;
  text-align: center;
  text-decoration: none;
  transition: 0.3s;
}
@media not all and (min-width: 768px) {
  .p-top-recruit .card-recruit {
    border-left: none;
  }
}
.p-top-recruit .card-recruit::before {
  content: "";
  display: block;
  margin: 0 auto 20px;
  width: 80px;
  height: 70px;
  transition: 0.3s;
}
@media not all and (min-width: 768px) {
  .p-top-recruit .card-recruit::before {
    margin-bottom: calc( 8px * 4 );
  }
}
@media not all and (min-width: 600px) {
  .p-top-recruit .card-recruit::before {
    margin-bottom: calc( 8px * 1 );
    width: 56px;
    height: 56px;
    padding: 34px 0 34px;
  }
}
.p-top-recruit .card-recruit:hover {
  opacity: 1;
}
@media (min-width: 768px) {
  .p-top-recruit .card-recruit:hover {
    border-left-color: transparent;
    box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
  }
}
.p-top-recruit .card-recruit.primary {
  border-left: none;
}
.p-top-recruit .card-recruit.primary::before {
  background: url(../img/top/recruit/recruit_icon1.svg) no-repeat center bottom;
  background-size: 100% auto;
}
.p-top-recruit .card-recruit.secondary::before {
  background: url(../img/top/recruit/recruit_icon2.svg) no-repeat center bottom;
  background-size: 100% auto;
}
.p-top-recruit .card-recruit.tertiary::before {
  background: url(../img/top/recruit/recruit_icon3.svg) no-repeat center bottom;
  background-size: 100% auto;
}
.p-top-recruit .card-recruit .card-title {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px 0;
  line-height: normal;
  position: relative;
  z-index: 1;
}
@media not all and (min-width: 768px) {
  .p-top-recruit .card-recruit .card-title {
    display: block;
  }
}
.p-top-recruit .card-recruit .card-title span {
  color: #1847c7;
  display: inline-block;
  font-weight: 700;
  line-height: 1;
  padding: 1px 8px 4px;
  position: relative;
}
.p-top-recruit .card-recruit .card-title span::before {
  background: #ffb180;
  background: linear-gradient(90deg, #ffb180 0%, #ff8566 100%);
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
.p-top-recruit .card-recruit:hover {
  text-decoration: none;
  opacity: 1;
  transition: 0.3s;
}
.p-top-recruit .card-recruit:hover .card-title span {
  color: white;
}
.p-top-recruit .card-recruit:hover .card-title span::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}

/* ==[ .p-top-aboutus ]==================================== */
.p-top-aboutus {
  background-color: #F0F0F0;
}
.p-top-aboutus .sec-inner {
  padding: 56px 0;
}
@media (min-width: 992px) {
  .p-top-aboutus .sec-inner .container-lg {
    max-width: 1142px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.p-top-aboutus .c-tti-unit {
  margin-bottom: calc( 8px * 5 );
}
.p-top-aboutus .ttl-main {
  line-height: normal;
}
@media not all and (min-width: 768px) {
  .p-top-aboutus .card-unit {
    padding: 0;
  }
}
@media not all and (min-width: 768px) {
  .p-top-aboutus .card-unit + .card-unit {
    margin-top: calc( 8px * 3 );
  }
}
.p-top-aboutus .card-aboutus {
  background-color: white;
  box-shadow: 0 -1px 10px 0px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  transform: translateY(0);
  transition: 0.8s ease;
  font-family: "Lato", "Noto Sans JP", -apple-system, BlinkMacSystemFont, "メイリオ", Meiryo, "Hiragino Kaku Gothic ProN", sans-serif;
}
@media not all and (min-width: 768px) {
  .p-top-aboutus .card-aboutus {
    flex-direction: row;
  }
}
.p-top-aboutus .card-aboutus:hover {
  box-shadow: 0 -1px 20px 0px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
  opacity: 1;
}
.p-top-aboutus .card-aboutus:hover .card-img img {
  transform: scale(1.15);
  transition: 0.8s ease;
}
.p-top-aboutus .card-aboutus:hover .card-text {
  color: #1847c7;
  transition: 0.3s ease;
}
.p-top-aboutus .card-aboutus .card-img {
  overflow: hidden;
}
@media not all and (min-width: 768px) {
  .p-top-aboutus .card-aboutus .card-img {
    width: 150px;
    height: 130px;
  }
}
@media screen and (max-width: 374px) {
  .p-top-aboutus .card-aboutus .card-img {
    height: auto;
    width: 100px;
  }
}
.p-top-aboutus .card-aboutus .card-img.c-ratio {
  overflow: hidden;
}
.p-top-aboutus .card-aboutus .card-img.c-ratio::before {
  padding-top: 54.0462%;
}
@media not all and (min-width: 768px) {
  .p-top-aboutus .card-aboutus .card-img.c-ratio::before {
    padding-top: 86.666%;
  }
}
.p-top-aboutus .card-aboutus .card-img img {
  transition: 0.5s ease;
  height: auto;
}
@media not all and (min-width: 768px) {
  .p-top-aboutus .card-aboutus .card-img img {
    height: 100%;
  }
}
.p-top-aboutus .card-aboutus .card-body {
  padding: 16px 16px 24px;
}
@media not all and (min-width: 768px) {
  .p-top-aboutus .card-aboutus .card-body {
    width: calc(100% - 150px);
  }
}
@media screen and (max-width: 374px) {
  .p-top-aboutus .card-aboutus .card-body {
    width: calc(100% - 100px);
  }
}
.p-top-aboutus .card-aboutus .card-text {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 700;
}
@media not all and (min-width: 768px) {
  .p-top-aboutus .card-aboutus .card-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
}
@media screen and (max-width: 374px) {
  .p-top-aboutus .card-aboutus .card-text {
    font-size: 12px;
    -webkit-line-clamp: 2;
  }
}

/* ==[ .p-top-info ]==================================== */
.p-common-info {
  background-color: #1847c7;
}
.p-common-info .sec-inner {
  padding: 56px 24px 0;
}
@media not all and (min-width: 600px) {
  .p-common-info .sec-inner {
    padding: 56px 18px 0;
  }
}
.p-common-info .sec-inner .c-container-lg {
  max-width: 1110px;
}
@media not all and (min-width: 600px) {
  .p-common-info .card-unit {
    padding: 0 !important;
  }
}
@media not all and (min-width: 600px) {
  .p-common-info .card-unit + .card-unit {
    margin-top: 16px;
  }
}
.p-common-info .info-card .card-img {
  text-align: center;
  position: relative;
  overflow: hidden;
}
.p-common-info .info-card .card-img img {
  position: relative;
  transition: 0.8s;
}
.p-common-info .info-card .card-img .info-ttl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media not all and (min-width: 600px) {
  .p-common-info .info-card .card-img .info-ttl {
    width: 70%;
  }
}
.p-common-info .info-card .card-img .info-ttl__img {
  width: auto;
  max-width: 100%;
  transition: 0.8s;
}
.p-common-info .info-card:hover {
  opacity: 1;
}
.p-common-info .info-card:hover .card-img img:not(.info-ttl__img) {
  transform: scale(1.15);
  transition: 0.8s;
}
.p-common-info .info-card:hover .info-ttl__img {
  transform: translateY(-5px);
  transition: 0.8s;
}
.p-common-info .info-card.h-none {
  pointer-events: none;
}