@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

/*
top
*/
$PAGE_ID: top;
$PATH: '../';
$COMMON_PATH: '#{$PATH}img/common/';
$IMAGE_PATH: '#{$PATH}img/#{$PAGE_ID}/';

/* margin / 余白 */
.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb150 {
  margin-bottom: 150px;
}

/* ==[ .p-top-hero ]==================================== */
.p-top-hero {


  .p-top-mv {

    &__inner {

      margin-left: auto;
      @include mixin.multiple-p('bottom', '3');
      width: 95.83%;
      position: relative;

      // @include mixin.tabletL {
      //   @include mixin.multiple-p('bottom', '4');
      // }
    }

    .mv-ttl {
      position: absolute;
      left: 52px;
      /* top: 50%; */
      position: absolute;
      bottom: 4vw;
      transform: translateY(-50%);
      z-index: 3;
      width: 43%;
      min-width: 420px;

      @include mixin.tabletL {
        bottom: 0;
        max-width: 100%;
        transform: translateY(-50%);
      }

      @include mixin.sp {
        transform: translate(-50%, -10%);
        left: 50%;
        padding: 0 16px;
        bottom: 20%;
        width: 100%;
      }
    }

    .animat-box {
      width: 450px;
      position: absolute;
      /* top: 100px; */
      bottom: 5vw;
      width: 35%;
      max-width: 590px;
      left: -110px;
      z-index: 2;

      @include mixin.tabletP {
        width: 400px;
      }

      @include mixin.sp {
        width: 400px;
      }
      @media screen and (max-width: 425px) {
        bottom: 30px;
        top: auto;
        width: 225px;
        left: -52px;
        bottom: 15%;
      }
    }

    .p-search-area {
      background-color: white;
      padding: 40px 24px;
      /* width: 624px; */
      width: calc(624 / 1200 * 100vw);
      max-width: 624px;
      min-width: 400px;
      position: absolute;
      right: 0;
      z-index: 3;

      &.pc-search {
        bottom: 0;

        @include mixin.tabletL {
          display: none;
        }
      }

      &.sp-search {
        display: none;

        @include mixin.tabletL {
          display: block;
        }
      }

      @include mixin.tabletL {
        box-shadow: 0px 7px 15px 1px rgba(0, 0, 0, .1);
        @include mixin.multiple-p(null, '4');
        position: static;
        right: auto;
        bottom: auto;
        width: 100%;
        max-width: 100%;
        min-width: auto;
      }

      @include mixin.sp {
        padding: 40px 24px;
      }

      .p-search-unit {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 18px;

        @include mixin.tabletP {
          flex-direction: column;
          gap: 16px 0;
        }

        li {

          @include mixin.tabletP {
            text-align: center;
            width: 100%;
          }
        }
      }

      .custom-select {
        height: 42px;
        width: 326px;
        max-width: 100%;
        transition: .3s;
        box-shadow: 0 0 0 0 rgba(0,0,0,0);

        &:hover{
          background-color: #e3e7eb;
        }

        /* &:focus{
          background-color: #f2f2f2;
          box-shadow: 0 0 10px 0 rgba(45, 45, 45, 0.15);
        }
        &:focus-visible {
          outline: none !important;
        } */

        &:focus:not(:focus-visible) {
          outline: none;
        }

        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px;
        }

        @include mixin.tabletP {
          height: 42px;
          background: #fff url(../img/common/icon_select_btn.svg) 100%/42px 42px no-repeat;
          background-color: #f0f0f0;
          font-size: 14px;
        }

        @include mixin.tabletP {
          width: 100%;
        }
      }

      .c-btn {
        line-height: 42px;
        padding: 0 8px;
        width: 167px;
        max-width: 100%;

        span{
          font-size: 14px;
        }

        @include mixin.tabletP {
          line-height: 54px;
          width: 326px;
          font-size: 16px;
        }

        @include mixin.sp {
          width: 100%;
        }

      }
    }

    .slider-mv {

      .swiper-slide {

        &__inner {

          @include mixin.tabletP {
            /* height: 460px; */

            img {
              width: 100%;
              height: 100%;
            }
          }

          &:hover {
            opacity: 1;
          }
        }
      }

      .swiper-pagination-mv {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        gap: 8px 0;
        width: auto;
        position: absolute;
        bottom: auto;
        left: auto;
        top: 50%;
        right: 32px;
        transform: translateY(-50%);
        z-index: 2;

        .swiper-pagination-bullet {
          background-color: rgba(255, 255, 255, .3);
          border-radius: 50%;
          margin-right: 4px;
          width: 8px;
          height: 8px;
          opacity: 1;
        }

        .swiper-pagination-bullet-active {
          background-color: transparent;
          background-image: url(#{mixin.set_common_path('pagination_bullet_active.svg')});
          background-size: 100% auto;
          border-radius: 0;
          margin: 0;
          width: 17px;
          height: 17px;
          opacity: 1;
        }
      }
    }
  }

  .p-top-news {
    background-color: variables.$COLOR_MAIN;
    padding-top: 245px;
    margin-top: -245px;

    &__inner {
      margin-left: auto;
      @include mixin.multiple-p('right', '5');
      @include mixin.multiple-p('bottom', '4');
      width: 95.83%;
      position: relative;
      overflow: hidden;
      z-index: 5;

      @include mixin.tabletL {
        @include mixin.multiple-p('right', '2');
        @include mixin.multiple-p('bottom', '4');
      }
    }

    .slider-news {
      position: relative;

      .swiper-slide {
        background-color: variables.$COLOR_MAIN;
      }

      .swiper-pagination-news {
        width: auto;
        /* position: absolute;
        bottom: auto;
        top: -44px;
        left: 140px; */

        .swiper-pagination-bullet {
          background-color: white;
          border-radius: 30px;
          height: 4px;
          width: 49px;

          @include mixin.tabletP {
            height: 3px;
            width: 42px;
          }
        }
      }
    }

    /* 20241114加筆 */
    .myBlockNews {

      &__title {
        background-color: map_get(variables.$COLOR, middle-gray);
        color: variables.$COLOR_MAIN;
        display: inline-block;
        font-size: calc(14px + 2 * (100vw - 375px) / 1545);
        font-weight: 700;
        padding: 8px 12px;
        margin-bottom: 16px;
        text-align: center;
        width: 104px;
        line-height: 1;
        white-space: nowrap;

        @include mixin.tabletP {
          padding: 6px;
          width: 100px;
        }
      }

      &__list {
        display: flex;
        gap: 5px 0;
        flex-direction: column;

        time,
        span {
          font-size: 16px;

          @include mixin.tabletP {
            font-size: 14px;
          }
        }

        &-item {
          color: white;
          line-height: normal;
          text-decoration: none;
        }

        &-update {
          display: inline-block;
          width: 160px;

          @include mixin.tabletP {
            width: 140px;
          }
        }

        &-text {
          @include mixin.propertyClamp("font-size", 14, 16, 769, 960);
        }
      }
    }

  }
}

/* ==[ .p-top-msg ]==================================== */
.p-top-msg {

  .sec-inner {
    overflow: hidden;
    @include mixin.multiple-p('top', '13');
    @include mixin.multiple-p('bottom', '18');

    @include mixin.tabletP {
      @include mixin.multiple-p('top', '8');
      @include mixin.multiple-p('bottom', '10');
    }
  }

  .c-container-lg {
    padding: 0 24px;
    @include mixin.tabletP {
    }
  }

  .c-ttl-unit {
    @include mixin.multiple-m('bottom', '5');

    @include mixin.tabletP {
      @include mixin.multiple-m('bottom', '4');
    }
  }

  .ttl-main {
    color: #231815;
    font-size: calc(36px + 30 * (100vw - 375px) / 1600);
    line-height: 1.579;
    word-break: keep-all;

    @media (min-width: 1680px) {
      font-size: 62px;
    }

    @media screen and (max-width: 374px ) {
      font-size: calc(34px + 4 * (100vw - 375px) / 1545);
    }
  }

  .msg-txt {
    font-size: calc(18px + 4 * (100vw - 375px) / 1545);
    font-weight: 700;
    line-height: 1.8;
    line-height: clamp(40px, 4.8vh , 48px);
    word-break: keep-all;

    @media screen and (max-width: 374px ) {
      font-size: calc(16px + 2 * (100vw - 375px) / 1545);
    }
  }

  .animat-msg {
    width: 90%;
    max-width: 1098px;
    bottom: -50px;
    right: -28vw;

    @include mixin.tabletL {
      right: -25vw;
      max-width: 100%;
    }

    @include mixin.tabletP {
      bottom: -10vw;
      right: -30vw;
    }
  }
}

/* ==[ .p-top-philosophy ]==================================== */
.p-top-philosophy {
  background-color: #F0F0F0;

  .sec-inner {
    padding-top: 0;
    @include mixin.multiple-p('bottom', '14');
    position: relative;

    @include mixin.tabletP {
      @include mixin.multiple-p('bottom', '4');
    }

    @include mixin.sp {
      @include mixin.multiple-p('bottom', '7');
    }
  }

  .p-heading-unit {
    background-image: url(#{mixin.set_image_path('top/philosophy/philosophy_ttl_bg.svg')});
    background-position: left center;
    background-size: contain;
    background-repeat: repeat-x;
    width: 100%;
    height: 150px;

    &.heading-type1 {
      top: -84px;
      /* left: 120%; */
    }

    &.heading-anime1 {
      left: 0;
    }
  }

  .p-heading-flex {
    gap: 0 30px;
  }

  .container-full {
    width: 100%;
    max-width: 100%;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);

    @include mixin.tabletP {
      position: static;
      top: auto;
      left: auto;
      transform: none;
      margin-top: -80px;
    }
  }

  .width-md {
    position: relative;

    @include mixin.tabletP {
      margin: 0 auto;
      width: calc(100% - 32px);
    }
  }

  .p-philosophy-bg {
    height: 400px;
    @include mixin.propertyClamp("height", 350, 520, 820, 1920);//ブラウザ幅 820px-350px,1920px-520px
    position: relative;

    @include mixin.tabletP {
      height: auto;
      max-height: 500px;
    }

    &::before {
      content: "";
      display: block;
      padding-top: 42.2037%;

      @include mixin.tabletP {
        padding-top: 72%;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/* ==[ .p-top-company ]==================================== */
.p-top-company {

  .animat-common {
    width: 1200px;
    max-width: 100%;
    top: 50%;
    right: -20vh;
    transform: translateY(-50%);
    z-index: 0;

    &.bottom {
      bottom: -50px;
      right: -20vw;
      z-index: 0;
    }

    @include mixin.tabletP {
      display: none;
    }
  }

  .container-full {
    @include mixin.multiple-p('top', '6');
    @include mixin.multiple-p('bottom', '4');
    position: relative;
    z-index: 1;

    @include mixin.tabletP {
      padding-bottom: 250px;
    }
    @include mixin.sp {
      padding-bottom: 160px;
      padding-top: 62px;
    }
  }

  .p-heading-unit {

    &.heading,
    &.heading-type2 {
      padding-left: 6.5%;
      height: 130px;
      top: 0;
      left: 0;

      @include mixin.tabletP {
        @include mixin.multiple-p('left', '2');
        top: -5px;
      }
    }
  }

  .width-sm {
    box-shadow: 0px 7px 15px 1px rgba(0, 0, 0, .1);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .width-lg {
    margin-left: auto;
    position: relative;
    z-index: 0;
  }

  .col-img {
    height: 400px;
    @include mixin.propertyClamp("height", 350, 520, 820, 1920);//ブラウザ幅 820px-350px,1920px-520px
    padding: 0;

    @include mixin.tabletP {
      height: auto;
    }
    @include mixin.sp {
      height: 350px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.object-fit-img {
        object-position: right;

        @include mixin.tabletP {
          object-position: center;
        }
      }
    }
  }
}

/* ==[ .p-top-work ]==================================== */
.p-top-work {

  .animat-common {
    width: 1200px;
    max-width: 100%;
    z-index: 0;

    &.bottom {
      bottom: -50px;
      right: -20vw;
      z-index: 0;
    }

    @include mixin.tabletP {
      display: none;
    }
  }

  .sec-inner {
    padding: 48px 0 0;
    @include mixin.multiple-m('bottom', '9');
    position: relative;
    z-index: 1;

    @include mixin.tabletP {
      margin-bottom: 0;
      padding-bottom: 92px;
    }
    @include mixin.sp {
      padding: 64px 0 0;
    }
  }

  .c-container-lg {

    &.p-absolute {
      position: absolute;
      top: 0;
      z-index: 2;
    }
  }

  .p-heading-unit  {
    max-width: 100%;

    &.heading-type2 {
      @media screen and (max-width: 576px) {
        top: -35px;
      }
    }

    .p-heading-flex {

      @media screen and (max-width: 576px) {
        white-space: normal;
        flex-wrap: wrap;
      }

      @media screen and (max-width: 374px ) {
        flex-wrap: nowrap;
      }

      li {
        font-size: calc(52px + 86 * (100vw - 320px) / 1600);

        @media screen and (max-width: 374px ) {
          font-size: calc(52px + 86 * (100vw - 320px) / 1600);
        }

        span {

          @media screen and (max-width: 576px) {
            padding: 0 12px;
          }
        }
      }
    }
  }

  .pc-wrap {
    display: block;

    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  .sp-wrap {
    display: none;

    @media screen and (max-width: 576px) {
      display: block;
    }
  }
}

/* ==[ .p-top-people ]==================================== */
.p-top-people {

  .sec-inner {
    padding-top: 0;
    @include mixin.multiple-p('bottom', '7');

    @include mixin.tabletP {
      padding-top: 16px;
      padding-bottom: 92px;
    }
    @include mixin.sp {
      padding: 32px 0 0;
    }
  }

  .col-right {
    position: relative;
  }

  .animation-wrap {
    position: relative;
    overflow: hidden;
    padding: 0;

    .loop-anim-unit {
      padding: 0 72px;
      width: 80%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;

      @include mixin.tabletL {
        padding-right: 24px;
        width: calc(100% - 140px);
      }

      @include mixin.tabletP {
        padding: 0 16px;
        padding-left: 40px;
        width: 100%;
      }

      .flex-unit {
        display: flex;
        justify-content: flex-end;
        gap: 0 35px;
        padding: 0;

        @include mixin.tabletP {
          gap: 0 18px;
        }

        .flex-item {
          display: flex;
          flex-direction: column;
          gap: 30px 0;
          transform: translate3d(0, 0, 0);
          width: calc(100% / 2);

          @include mixin.tabletP {
            gap: 20px 0;
          }

          &.item-up {
            text-align: right;
            animation: loopUp 35s linear infinite;


          }

          &.item-down {
            animation: loopDown 35s linear infinite;

          }
        }
      }

      img {
        border-radius: 14px;
        width: auto;
        height: auto;
        min-width: 120px;
      }
    }
  }
}

/* ==[ .p-top-story ]==================================== */
.p-top-story {

  .sec-inner {
    @include mixin.multiple-p('top', '5');
    padding-bottom: 0;

    @include mixin.tabletP {
      padding-bottom: 92px;
    }
    @include mixin.sp {
      padding: 64px 0 0;
      margin-top: 32px;
    }
  }
}

/* ==[ .p-top-environment ]==================================== */
.p-top-environment {
  color: white;

  .sec-inner {
    @include mixin.multiple-p('top', '11');
    padding-bottom: 0;

    @include mixin.sp {
      padding: 64px 0 0;
      margin-top: 32px;
    }
  }

  .ttl-main {
    color: white;
  }

  .heading {
    height: 125px;
    width: 1200px;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    .p-heading-flex {
      justify-content: flex-end;
    }
  }

  .width-xl {
    background: url(#{mixin.set_image_path('top/environment/environment_bg.jpg')}) no-repeat center center;
    background-size: cover;
    padding: 88px 0 72px;
    margin-left: auto;
    position: relative;

    @include mixin.tabletP {
      background: url(#{mixin.set_image_path('top/environment/environment_bg_sp.jpg')}) no-repeat center center;
      background-size: cover;
      padding: 88px 0;
    }

    @include mixin.sp {
      padding: 72px 0 64px;
    }

    &::after {
      background-color: rgba(23, 70, 199, 0.75);
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .c-container-xl {
    position: relative;
    z-index: 1;
  }

  .c-flex-container {
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;

    @include mixin.tabletP {
      flex-wrap: wrap;
    }

    .c-flex-item {
      width: calc(100% / 2 - 40px);
      word-break: keep-all;

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }

  .c-contxt {
    @include mixin.tabletP {
      @include mixin.multiple-m('bottom', '11');
    }
    @include mixin.sp {
      @include mixin.multiple-m('bottom', '3');
    }
  }
}

/* ==[ .p-top-recruit ]==================================== */
.p-top-recruit {
  overflow: hidden;

  .animat-common {
    width: 1200px;
    max-width: 100%;
    top: 50%;
    right: -50vh;
    transform: translateY(-50%);
    z-index: 0;

    @include mixin.tabletL {
      width: 858px;
      top: -10vw;
      right: -30vw;
      transform: translateY(0);
    }

    @include mixin.tabletP {
      top: 0;
    }

    &.bottom {
      display: none;

      @include mixin.tabletL {
        display: block;
        width: 858px;
        top: auto;
        bottom: -50px;
        right: -30vw;
      }

      @include mixin.tabletP {
        bottom: 0;
      }
    }
  }

  .sec-inner {
    padding: 56px 0 120px;
    position: relative;
    z-index: 1;

    @include mixin.tabletP {
      padding: 56px 0 136px;
    }
    @include mixin.sp {
      padding: 56px 0 80px;
    }
  }

  .outer-unit {
    margin: 0 auto;
    padding-top: 120px;
    width: 87.91666%;
    position: relative;

    @include mixin.tabletP {
      padding-top: 30px;
      width: calc(100% - 32px);
    }
  }

  .heading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

    @include mixin.tabletP {
      padding-left: 0;
      padding-right: 16px;
      top: -5px;
      left: auto;
      right: 0;
    }

    .p-heading-flex {
      height: 125px;
      align-items: flex-end;

      @include mixin.tabletP {
        height: auto;
      }
    }
  }

  .width-custom {
    background-color: rgba(255, 255, 255, .95);
    box-shadow: 0px 7px 15px 1px rgba(0, 0, 0, 0.13);
    padding: 62px 0;
    width: 100%;
    max-width: 100%;
  }

  .c-ttl-unit {
    text-align: center;
    margin-bottom: 40px;

    @include mixin.sp {
      margin-bottom: 32px;
    }
  }

  .ttl-main,
  .c-card-title {
    color: variables.$COLOR_MAIN;
  }

  .c-flex-container {
    padding: 0 16px;
    justify-content: center;

    @include mixin.tabletP {
      flex-wrap: wrap;
    }

    .c-flex-item {
      width: calc(100% / 3);

      @include mixin.tabletP {
        width: 100%;
      }

      +.c-flex-item {

        @include mixin.tabletP {
          @include mixin.multiple-m('top', '8');
        }
        @include mixin.sp {
          @include mixin.multiple-m('top', '0');
        }
      }

      &:hover {
        +.c-flex-item {
          .card-recruit {
            border-left-color: white;
          }
        }
      }
    }
  }

  .card-recruit {
    border-left: 1px #E5E5E5 solid;
    background-color: white;
    color: white;
    padding: 22px 0 34px;
    text-align: center;
    text-decoration: none;
    transition: .3s;

    @include mixin.tabletP {
      border-left: none;
    }

    &::before {
      content: "";
      display: block;
      margin: 0 auto 20px;
      width: 80px;
      height: 70px;
      transition: .3s;

      @include mixin.tabletP {
        @include mixin.multiple-m('bottom', '4');
      }
      @include mixin.sp {
        @include mixin.multiple-m('bottom', '1');
        width: 56px;
        height: 56px;
        padding: 34px 0 34px;
      }
    }

    &:hover {
      opacity: 1;

      @include mixin.tabletP-min {
        border-left-color: transparent;
        box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
      }
    }

    &.primary {
      border-left: none;

      &::before {
        background: url(#{mixin.set_image_path('top/recruit/recruit_icon1.svg')}) no-repeat center bottom;
        background-size: 100% auto;
      }
    }

    &.secondary {
      &::before {
        background: url(#{mixin.set_image_path('top/recruit/recruit_icon2.svg')}) no-repeat center bottom;
        background-size: 100% auto;
      }
    }

    &.tertiary {
      &::before {
        background: url(#{mixin.set_image_path('top/recruit/recruit_icon3.svg')}) no-repeat center bottom;
        background-size: 100% auto;
      }
    }

    .card-title {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px 0;
      line-height: normal;
      position: relative;
      z-index: 1;

      @include mixin.tabletP {
        display: block;
      }

      span {
        color: variables.$COLOR_MAIN;
        display: inline-block;
        font-weight: 700;
        line-height: 1;
        padding: 1px 8px 4px;
        position: relative;

        &::before {
          background: rgb(255,177,128);
          background: linear-gradient(90deg, rgba(255,177,128,1) 0%, rgba(255,133,102,1) 100%);
          content: "";
          display: inline-block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          transform-origin: 100% 50%;
          transform: scaleX(0);
          transition: transform .3s ease;
        }
      }
    }

    &:hover {
      text-decoration: none;
      opacity: 1;
      transition: .3s;

      .card-title {
        span {
          color: white;

          &::before {
            transform-origin: 0% 50%;
            transform: scaleX(1);
          }

        }
      }
    }
  }
}

/* ==[ .p-top-aboutus ]==================================== */
.p-top-aboutus {
  background-color: map_get(variables.$COLOR, middle-gray);

  .sec-inner {
    padding: 56px 0;

    .container-lg{
      @media (min-width: 992px) {
        max-width: 1142px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

  }

  .c-tti-unit {
    @include mixin.multiple-m('bottom', '5');
  }

  .ttl-main {
    // font-size: 32px;
    line-height: normal;
  }

  .card-unit {

    @include mixin.tabletP {
      padding: 0;
    }

    +.card-unit {

      @include mixin.tabletP {
        @include mixin.multiple-m('top', '3');
      }
    }
  }

  .card-aboutus {
    background-color: white;
    box-shadow: 0 -1px 10px 0px rgba(0, 0, 0, .15);
    text-decoration: none;
    transform: translateY(0);
    transition: .8s ease;
    font-family: 'Lato', 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'メイリオ', Meiryo, 'Hiragino Kaku Gothic ProN', sans-serif;

    @include mixin.tabletP {
      flex-direction: row;
    }

    &:hover {
      box-shadow: 0 -1px 20px 0px  rgba(0, 0, 0, .1);
      transform: translateY(-5px);
      opacity: 1;

      .card-img {

        img {
          transform: scale(1.15);
          transition: .8s ease;
        }
      }

      .card-text {
        color: variables.$COLOR_MAIN;
        transition: .3s ease;
      }
    }

    .card-img {
      overflow: hidden;

      @include mixin.tabletP {
        width: 150px;
        height: 130px;
      }

      @media screen and (max-width: 374px ) {
        height: auto;
        width: 100px;
      }

      &.c-ratio {
        overflow: hidden;

        &::before {
          padding-top: 54.0462%;

          @include mixin.tabletP {
            padding-top: 86.666%;
          }
        }
      }

      img {
        transition: .5s ease;
        height: auto;
        @include mixin.tabletP {
          height: 100%;
        }
      }
    }

    .card-body {
      padding: 16px 16px 24px;

      @include mixin.tabletP {
        width: calc(100% - 150px);
      }

      @media screen and (max-width: 374px ) {
        width: calc(100% - 100px);
      }
    }

    .card-text {
      font-size: calc(14px + 2 * (100vw - 320px) / 1600);
      font-weight: 700;

      @include mixin.tabletP {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
      }

      @media screen and (max-width: 374px ) {
        font-size: 12px;
        -webkit-line-clamp: 2;
      }
    }
  }
}

/* ==[ .p-top-info ]==================================== */
.p-common-info {
  background-color: variables.$COLOR_MAIN;

  .sec-inner {
    padding: 56px 24px 0;

    @include mixin.sp {
      padding: 56px 18px 0;
    }

    .c-container-lg{
      max-width: 1110px;
    }
  }
  .card-unit {

    @include mixin.sp {
      padding: 0 !important;
    }

    +.card-unit {

      // @include mixin.tabletP {
      //   margin-top: 26px;
      // }

      @include mixin.sp {
        margin-top: 16px;
      }
    }
  }

  .info-card {
    .card-img {
      text-align: center;
      position: relative;
      overflow: hidden;

      img {
        position: relative;
        transition: .8s;
      }

      .info-ttl {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include mixin.sp {
          width: 70%;
        }

        &__img {
          width: auto;
          max-width: 100%;
          transition: .8s;
        }
      }
    }

    &:hover {
      opacity: 1;

      .card-img {
        img:not(.info-ttl__img) {
          transform: scale(1.15);
          transition: .8s;
        }
      }

      .info-ttl__img {
        transform: translateY(-5px);
        transition: .8s;
      }
    }
    &.h-none{
      pointer-events: none;
    }
  }

}